<template>
  <div class="mynav">
    <div class="con-w">
      <img src="../assets/logo.png" alt="" class="nav-logo" />
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#353c56"
        text-color="#fff"
        active-text-color="#d8584f"
      >
        <el-submenu index="1" class="nav-list">
          <template v-slot:title>千锋概况</template>
          <el-menu-item index="1-1">
            <a href="http://www.1000phone.com/index.html#about" target="_blank"
              >关于千锋</a
            >
          </el-menu-item>
          <el-menu-item index="1-2">
            <a
              href="http://www.1000phone.com/index.html#business"
              target="_blank"
              >业务体系</a
            >
          </el-menu-item>
          <el-menu-item index="1-3">
            <a href="http://www.1000phone.com/index.html#ip" target="_blank"
              >品牌矩阵</a
            >
          </el-menu-item>
          <el-menu-item index="1-4">
            <a
              href="http://www.1000phone.com/index.html#monologue"
              target="_blank"
              >千锋人独白</a
            >
          </el-menu-item>
          <el-menu-item index="1-5">
            <a href="http://www.1000phone.com/index.html#news" target="_blank"
              >新闻动态</a
            >
          </el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="1" class="nav-list">
          <a href="http://www.1000phone.com/index.html" target="_blank"
            >千锋概况</a
          >
        </el-menu-item> -->
        <el-submenu index="2" class="nav-list">
          <template v-slot:title>教育培训</template>
          <el-menu-item index="2-1">
            <a href="http://www.qfedu.com/" target="_blank">千锋教育</a>
          </el-menu-item>
          <el-menu-item index="2-2">
            <a href="http://www.goodprogrammer.org/" target="_blank"
              >好程序员</a
            >
          </el-menu-item>
          <el-menu-item index="2-3">
            <a href="http://www.xiaoshiedu.cn/" target="_blank">小狮视觉</a>
          </el-menu-item>
          <el-menu-item index="2-4">
            <a href="http://www.codingke.com/" target="_blank">扣丁学堂</a>
          </el-menu-item>
             <el-menu-item index="2-5">
            <a href="http://www.fengqiyoulian.com/" target="_blank">锋企优联</a>
          </el-menu-item>
          <!-- <el-submenu index="2-5">
            <template v-slot:title>职业技能认证</template>
            <el-menu-item index="2-5-1">
              <a href="http://www.mobiletrain.org/page/pmp/" target="_blank"
                >PMP</a
              >
            </el-menu-item>
            <el-menu-item index="2-5-2">
              <a href="http://www.mobiletrain.org/topic/redhat/" target="_blank"
                >红帽认证</a
              >
            </el-menu-item>
          </el-submenu> -->
        </el-submenu>
        <el-submenu index="3" class="nav-list">
          <template v-slot:title>高校服务</template>
          <el-submenu index="3-1">
            <template v-slot:title>校企合作</template>
            <el-menu-item index="3-1-1">
              <a
                href="http://www.fengyunedu.cn/collegeCooperation"
                target="_blank"
                >院校合作</a
              >
            </el-menu-item>
            <el-menu-item index="3-2-2">
              <a
                href="http://www.fengyunedu.cn/companyCooperation"
                target="_blank"
                >企业合作</a
              >
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3-2">
            <template v-slot:title>高校教辅平台</template>
            <el-menu-item index="3-2-1">
              <a href="http://tea.fengyunedu.cn/home" target="_blank"
                >教师登录</a
              >
            </el-menu-item>
            <el-menu-item index="3-2-2">
              <a href="http://stu.fengyunedu.cn/home" target="_blank"
                >学生登录</a
              >
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="3-3">
            <a href="https://fyl.fengyunedu.cn/" target="_blank">锋云录</a>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="4" class="nav-list">
          <template v-slot:title>企业服务</template>
          <el-menu-item index="4-1">
            <a href="http://www.mobiletrain.org/qynx/" target="_blank"
              >企业内训</a
            >
          </el-menu-item>
          <el-menu-item index="4-2">
            <a href="http://www.1000phone.com/service.html" target="_blank"
              >人才服务</a
            >
          </el-menu-item>
        </el-submenu>
        <el-submenu index="5" class="nav-list">
          <template v-slot:title>教研院</template>
          <el-menu-item index="5-1">
            <a
              href="http://www.mobiletrain.org/project2021/index.shtml"
              target="_blank"
              >教研实力</a
            >
          </el-menu-item>
          <el-menu-item index="5-3">
            <a href="http://books.1000phone.com/" target="_blank">教材书籍</a>
          </el-menu-item>
          <el-menu-item index="5-4">
            <a href="http://video.mobiletrain.org/" target="_blank">视频教程</a>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="6" class="nav-list">
          <a href="http://www.mobiletrain.org/topic/fygy.html" target="_blank"
            >锋益公益</a
          >
        </el-menu-item>
        <el-menu-item index="7" class="nav-list">
          <a
            href="http://www.mobiletrain.org/about/contact.html"
            target="_blank"
            >联系我们</a
          >
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "mynav",
  data() {
    return {
      activeIndex: "1",
    };
  },
};
</script>

<style>
.mynav {
  width: 100%;
  height: 60px;
  background-color: #353c56;
}
.mynav .con-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.con-w {
  margin: 0 auto;
  width: 1200px;
}
.mynav .nav-logo {
  display: block;
  width: 121px;
  height: 33px;
  margin-left: 1%;
}
.mynav .el-menu.el-menu--horizontal {
  border: none !important;
}
.mynav a {
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
}
.mynav .el-submenu__title,
.el-menu-item {
  font-size: 16px !important;
  color: #ffffff !important;
  border-bottom-color: #353c56 !important;
}

.mynav .el-submenu__title:hover,
.el-menu-item:hover,
.el-menu--horizontal .el-menu .el-submenu__title:hover {
  border-bottom-color: #d8584f !important;
  color: #d8584f !important;
  background: #353c56 !important;
}
.el-menu--horizontal .el-menu .el-menu-item a,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  font-size: 16px !important;
  color: #ffffff !important;
  text-decoration: none;
}
.el-menu--horizontal .el-menu .el-menu-item a:hover {
  color: #d8584f !important;
}
.el-popper.is-light {
  border: none !important;
  background: rgb(53, 60, 86) !important;
}
@media (min-width: 1600px) {
  .con-w {
    width: 1600px;
    margin: 0 auto;
  }
}
</style>
