<template>
  <div class="mycontent">
    <div class="banner">
      <swiper :options="swiperOptions" :pagination="{ clickable: true }">
        <swiper-slide>
          <div class="slide1">
            <div class="con-w">
              <img
                src="../assets/1-2.png"
                alt=""
                class="slide-2"
                ref="slideAni1"
              />
            </div>
            <div class="slide-3"></div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide2">
            <div class="con-w">
              <img
                src="../assets/2-2.png"
                alt=""
                class="slide2-2"
                ref="slideAni2"
              />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <a
            href="http://1000phone.com/occupation/"
            target="_blank"
            class="slide3"
          >
            <div class="con-w"></div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="c2">
      <div class="con-w">
        <div class="c2-left">
          <h3>砥砺奋进十载时光，初心不改再踏征程</h3>
          <p>
            十年来，千锋秉承“用良心做教育”的初心，用辛勤和汗水践行着教育工作者的责任和使命。值此十周年之际，诚挚感谢为千锋竭诚奉献的全体员工，感谢为千锋增光添彩的广大学员和校友，感谢关心支持千锋发展的领导、高校、企业合作伙伴及社会各界友人!
          </p>
          <p>
            迎着数字化发展的浪潮，千锋肩负着为国家培养高素质、高层次技术技能人才的重任，未来千锋将带着大家的支持与厚爱，继续深耕职业教育领域，秉承教育初心和匠心，大力培育现代技术工匠，为国家数字经济高质量发展做出新的、更大的贡献!
          </p>
        </div>
        <div class="c2-right">
          <img src="../assets/boss.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="c3" id="about">
      <div class="con-w">
        <div class="c3-text">
          <h3>关于千锋</h3>
          <p>北京千锋互联科技有限公司成立于2011年1月，立足于职业教育培训领域，公司现有教育培训、高校服务、企业服务三大业务板块。教育培训业务分为大学生技能培训和职后技能培训；高校服务业务主要提供校企合作全解决方案与定制服务；企业服务业务主要为企业提供专业化综合服务。公司总部位于北京，目前已在21个城市成立分公司，现有教研讲师团队300余人。公司目前已与国内20000余家IT相关企业建立人才输送合作关系，每年培养泛IT人才2万余人，累计培养近20万人，累计向互联网输出学科视频976余套，累积播放量超15000万余次。每年有数百万名学员接受千锋组织的技术研讨会、技术培训课、网络公开课及免费学科视频等服务。</p>
        </div>
        <ul class="c3-icon-lists">
          <li
            v-for="(item, index) in c3IconLists"
            :key="index"
            @mouseover="c3MouseOver(index)"
            @mouseout="c3Mouseout"
          >
            <img
              src="../assets/c3-i1.png"
              alt=""
              class="c3-i1"
              v-if="item.showAni"
            />
            <img
              src="../assets/c3-i2.png"
              alt=""
              class="c3-i2"
              v-if="item.showAni"
            />
            <img
              src="../assets/c3-i3.png"
              alt=""
              class="c3-i3"
              v-if="item.showAni"
            />
            <img
              src="../assets/c3-i4.png"
              alt=""
              class="c3-i4"
              v-if="item.showAni"
            />
            <img
              src="../assets/c3-i5.png"
              alt=""
              class="c3-i5"
              v-if="item.showAni"
            />
            <img :src="item.icon" alt="" />
          </li>
        </ul>
        <p class="c3-notes">以上数据来源于千锋内部统计，数据统计截止时间：2023年1月5日</p >
      </div>
    </div>
    <div class="c4" id="business">
      <div class="con-w">
        <h3 class="c4-tit">三大业务打造职业教育闭环</h3>
        <ul class="c4-lists">
          <li
            :class="{ active: item.activeFlag }"
            @mouseover="c4MouseOver(index)"
            v-for="(item, index) in c4Lists"
            :key="index"
          >
            <div class="list-box">
              <h3>{{ item.outer.tit }}</h3>
              <p class="list-stit">{{ item.outer.des }}</p>
              <p v-for="i in item.outer.tips" :key="i">{{ i }}</p>
            </div>
            <div class="list-inner-box" :class="{ active: item.activeFlag }">
              <img :src="item.inner.img" alt="" />
              <h3>{{ item.inner.tit }}</h3>
              <p>
                {{ item.inner.des }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="c5" id="ip">
      <h3 class="c5-tit">六大品牌助力学员终身发展</h3>
      <div class="c5-content con-w">
        <div class="c5-tabs">
          <span
            v-for="(item, index) in tabs"
            :key="index"
            @mouseover="c5Tabs(index)"
            ><img :src="item.icon" alt=""
          /></span>
        </div>
        <div
          class="c5-list"
          :class="{ active: item.activeFlag }"
          v-for="(item, index) in c5Lists"
          :key="index"
        >
          <h3
            :class="[
              'c5-list-tit',
              {
                cred: index === 1,
                cpur: index === 2,
                corange: index === 3,
                cblue: index === 4,
              },
            ]"
          >
            {{ item.tit1 }}<span>{{ item.tit2 }}</span>
          </h3>
          <div
            :class="[
              'c5-list-line',
              {
                bred: index === 1,
                bpur: index === 2,
                borange: index === 3,
                bblue: index === 4,
              },
            ]"
          >
            <i
              :class="[
                {
                  bred: index === 1,
                  bpur: index === 2,
                  borange: index === 3,
                  bblue: index === 4,
                  cblue2: index === 5,
                },
              ]"
            ></i>
          </div>
          <ul>
            <li
              v-for="(list, ind) in item.lists"
              :key="ind"
              :class="[
                {
                  width500: ind === 2 && index === 3,
                  width420:
                    (index === 5 && ind === 2) || (index === 5 && ind === 0),
                  width300:
                    (index === 5 && ind === 3) || (index === 5 && ind === 1),
                },
              ]"
            >
              {{ list }}
            </li>
          </ul>
          <a
            :href="item.btnUrl"
            target="_blank"
            :class="[
              'c5-list-btn',
              {
                bred: index === 1,
                bpur: index === 2,
                borange: index === 3,
                bblue: index === 4,
                bblue2: index === 5,
              },
            ]"
            >了解更多</a
          >
          <img :src="item.classLogo" alt="" class="c5-class-logo" />
          <div class="class-list-logo">
            <div
              :class="[
                'class-list-logo-bg',
                {
                  bred: index === 1,
                  bpur: index === 2,
                  borange: index === 3,
                  bblue: index === 4,
                  bblue2: index === 5,
                },
              ]"
            ></div>
            <img :src="item.listLogo" alt="" />
          </div>
          <div
            :class="[
              'c5-list-foot-line',
              {
                bred: index === 1,
                bpur: index === 2,
                borange: index === 3,
                bblue: index === 4,
                cblue2: index === 5,
              },
            ]"
          ></div>
        </div>
      </div>
    </div>
    <div class="c6" id="monologue">
      <div class="con-w">
        <img src="../assets/c6-img1.jpg" alt="" class="c6-img1" />
        <div class="c6-txt">
          一群草根奉献着自己的青春年华，为创造一个有真正社会价值的职业教育机构“千锋教育”而奋力拼搏！
          几十年后，当每一位千锋人回忆在千锋这段历程，相信内心深处是欣慰的。因为我们没有虚度年华，我们一起努力拼搏做了一件非常有意义的事情：帮助更多迷茫的学生实现他们的梦想！
          <span>——全体千锋人</span>
        </div>
      </div>
    </div>
    <div class="c7" id="news">
      <div class="con-w">
        <h3 class="tit">新闻动态</h3>
        <div class="c7-flex">
          <div class="c7-left"></div>
          <div class="c7-middle">
            <h3>
              “创新、变革、匠心、育人”千锋教研院2021教研升级战略发布会圆满结束
            </h3>
            <p>
              5月16日，千锋教育“千锋教研院2021教研升级战略发布会”于北京隆重召开，作为职业教育领域头部教育机构，千锋教育以其踏实勤勉的作风和志存高远的教育理想砥砺前行，此次教研升级战略发布以“创新
              变革 匠心
              育人”为主题，针对千锋教研战略进行从“服务到赋能”的教研升级，打造千锋教育独具特色的先进教研体系
            </p>
            <a
              href="http://www.mobiletrain.org/about/news/72995.html"
              class="c7-btn"
              target="_blank"
            ></a>
          </div>
          <div class="c7-right">
            <h3>千锋动态</h3>
            <ul>
              <li v-for="(item, index) in c7Lists" :key="index">
                <a :href="item.url" target="_blank" class="">{{ item.txt }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.min.css";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default {
  name: "mycontent",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        autoplay: true,
        on: {
          slideChange: () => {
            if (
              this.$refs.mySwiper.$swiper &&
              this.$refs.mySwiper.$swiper.realIndex === 1
            ) {
              if (this.$refs.slideAni2) {
                this.$refs.slideAni2.style.animation = "bottomTotop2 1s";
              }
            } else {
              if (this.$refs.slideAni1) {
                this.$refs.slideAni1.style.animation = "bottomTotop 1s";
              }
            }
          },
        },
      },
      tabs: [
        { icon: require("../assets/tab1.png") },
        { icon: require("../assets/tab2.png") },
        { icon: require("../assets/tab3.png") },
        { icon: require("../assets/tab4.png") },
        { icon: require("../assets/tab5.png") },
        { icon: require("../assets/tab6.png") },
      ],
      c4Lists: [
        {
          outer: {
            tit: "教育培训",
            des: "职业教育良心机构",
            tips: [
              "科学的课程研发体系",
              "健全的教研师资队伍",
              "严谨的教学品质管理",
            ],
          },
          inner: {
            tit: "教育培训",
            img: require("../assets/jy.jpg"),
            des: "千锋教育培训业务包含大学生技能培训和职后技能培训。其中包含HTML5大前端、JavaEE+分布式开发、Python人工智能+数据分析、全链路UI/UE设计、云计算、全栈软件测试、大数据+人工智能等14门课程；职后技能培训包含在线技能课、在职提升课、软考、华为认证、、PMP认证、红帽RHCE认证等。",
          },
          activeFlag: true,
        },
        {
          outer: {
            tit: "高校服务",
            des: "高校一站式协同服务平台",
            tips: [
              "全生态校企合作模式",
              "系统的产教融合机制",
              "完善的高校协同方案",
            ],
          },
          inner: {
            tit: "高校服务",
            img: require("../assets/gx.jpg"),
            des: " 千锋高校服务业务致力于推动校企合作，实现高校与企业无缝连接，为广大高校提供专业共建、协同育人、师资培训、教材研发、实习实训、企业招聘、编程大赛、教辅云平台等全方位一站式服务，积极响应国家产教融合、职业教育改革的政策号召，携手高校共同培养新一代高素质技术技能人才。",
          },
          activeFlag: false,
        },
        {
          outer: {
            tit: "企业服务",
            des: "助力企业数字化转型",
            tips: [
              "构建互联网人才生态链",
              "建立技术赋能提升闭环",
              "创新立体产业服务模式",
            ],
          },
          inner: {
            tit: "企业服务",
            img: require("../assets/qy.jpg"),
            des: " 千锋企业服务业务专注于围绕产业生态打造核心业务体系，通过合作企业优势资源整合，打磨出一套立体的产业服务新模式。包括：人才服务、企业培训、高校联合共建、行业认证服务等业务。为企业人才、技术、管理、运营提升提供多维度解决方案，助力行业发展，加速企业数字化转型。",
          },
          activeFlag: false,
        },
      ],
      c5Lists: [
        {
          tit1: "千锋教育",
          tit2: "职业教育品牌",
          lists: [
            "◉ 坚持教育初心，专注品质教学",
            "◉ 致力于打造全产业链人才服务平台",
            "◉ 教学大纲紧跟企业需求",
            "◉ 拥有全国一体化就业保障服务",
          ],
          btnUrl: "http://www.qfedu.com/",
          classLogo: require("../assets/c5-class-logo1.png"),
          listLogo: require("../assets/c5-1.png"),
          activeFlag: true,
        },
        {
          tit1: "好程序员",
          tit2: "IT职业教育实力品牌",
          lists: [
            "◉ 致力于架构师人才培养",
            "◉ 秉承严进严出的高品质培养模式",
            "◉ 技术赋能与综合素养全面提升",
            "◉ 重新定义行业培训体系",
          ],
          btnUrl: "http://www.goodprogrammer.org/",
          classLogo: require("../assets/c5-class-logo2.png"),
          listLogo: require("../assets/c5-2.png"),
          activeFlag: false,
        },
        {
          tit1: "小狮视觉",
          tit2: "CG教育创新品牌",
          lists: [
            "◉ 致力于CG设计人才培养",
            "◉ 对标大厂设计需求",
            "◉ 完善的CG设计课程体系",
            "◉ 打造CG教育新标杆",
          ],
          btnUrl: "http://xiaoshi.1000phone.com/",
          classLogo: require("../assets/c5-class-logo3.png"),
          listLogo: require("../assets/c5-3.png"),
          activeFlag: false,
        },
        {
          tit1: "扣丁学堂",
          tit2: "在线职业教育品牌",
          lists: [
            "◉ 专注在线职业教育",
            "◉ 总监级讲师，全天候跟踪答疑",
            "◉ 集零基础入门、技能提升及职业规划为一体的在线学习平台",
          ],
          btnUrl: "http://www.codingke.com/",
          classLogo: require("../assets/c5-class-logo4.png"),
          listLogo: require("../assets/c5-4.png"),
          activeFlag: false,
        },
        {
          tit1: "锋云智慧",
          tit2: "高校协同服务品牌",
          lists: [
            "◉ 致力于为高校提供全方位一站式服务",
            "◉ 积极推动产教融合",
            "◉ 深化职业教育改革",
            "◉ 携手高校共同培养新一代互联网人才",
          ],
          btnUrl: "http://www.fengyunedu.cn/",
          classLogo: require("../assets/c5-class-logo5.png"),
          listLogo: require("../assets/c5-5.png"),
          activeFlag: false,
        },
        {
          tit1: "锋企优联",
          tit2: "企业综合服务品牌",
          lists: [
            "◉ 致力于为企业提供立体化一站式服务",
            "◉ 助力企业数字化转型与持续发展",
            "◉ 建立快捷、高效、便捷、多渠道资源配置体系",
            "◉ 服务企业已达到20000余家",
          ],
          btnUrl: "http://www.mobiletrain.org/qynx/",
          classLogo: require("../assets/c5-class-logo6.png"),
          listLogo: require("../assets/c5-6.png"),
          activeFlag: false,
        },
      ],
      c7Lists: [
        {
          url: "http://www.mobiletrain.org/about/news/78079.html",
          txt: "千锋探索IT职业教育培训内涵式发展道路，着力培养应用型技术技能人才",
        },
        {
          url: "http://www.mobiletrain.org/about/news/77555.html",
          txt: "风雨共担 千锋“豫”你同行 践行社会责任与担当",
        },
        {
          url: "http://www.mobiletrain.org/about/news/69843.html",
          txt: "爱心传承 千锋教育太阳村公益农场用爱种下希望",
        },
        {
          url: "http://www.mobiletrain.org/about/news/69042.html",
          txt: "教育部公布2020年产学合作协同育人项目立项名单，千锋78个合作项目榜上有名",
        },
      ],
      c3IconLists: [
        { icon: require("../assets/c3-icon1.png"), showAni: false },
        { icon: require("../assets/c3-icon2.png"), showAni: false },
        { icon: require("../assets/c3-icon3.png"), showAni: false },
        { icon: require("../assets/c3-icon4.png"), showAni: false },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.c5Tabs(0);
    this.c4MouseOver(0);
  },
  methods: {
    c4MouseOver(index) {
      this.c4Lists.forEach((ele) => {
        ele.activeFlag = false;
      });
      this.c4Lists[index].activeFlag = true;
    },
    c5Tabs(index) {
      this.tabs.forEach((item, index) => {
        this.tabs[index].icon = require(`../assets/tab${index + 1}.png`);
      });
      this.c5Lists.forEach((item, index) => {
        this.c5Lists[index].activeFlag = false;
      });
      this.tabs[index].icon = require(`../assets/tab${index + 1}-ac.png`);
      this.c5Lists[index].activeFlag = true;
    },
    c3MouseOver(index) {
      this.c3IconLists.forEach((item, index) => {
        this.c3IconLists[index].icon = require(`../assets/c3-icon${
          index + 1
        }.png`);
        this.c3IconLists[index].showAni = false;
      });
      this.c3IconLists[index].icon = require(`../assets/c3-icon${
        index + 1
      }-active.png`);
      this.c3IconLists[index].showAni = true;
    },
    c3Mouseout() {
      this.c3IconLists.forEach((item, index) => {
        this.c3IconLists[index].icon = require(`../assets/c3-icon${
          index + 1
        }.png`);
        this.c3IconLists[index].showAni = false;
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
.con-w {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  /* background: rgba(17, 17, 17, 0.5); */
}
.banner {
  height: 850px;
}
.common_banner img {
  display: block;
  height: 850px;
  margin: 0 auto;
}

.banner .slide1 {
  height: 850px;
  background: url(../assets/banner1.jpg) no-repeat;
  background-size: 2000px 850px;
  background-position: center top;
}
.banner .slide2 {
  width: 100%;
  height: 850px;
  background: url(../assets/banner2.jpg) no-repeat;
  background-size: 2000px 850px;
  background-position: center top;
}
.banner .slide3 {
  display: block;
  width: 100%;
  height: 850px;
  background: url(../assets/banner3.jpg) no-repeat;
  background-size: 2000px 850px;
  background-position: center top;
}
.banner .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  opacity: 0.5;
}
.banner .swiper-pagination-bullet-active {
  width: 32px;
  height: 10px;
  background: linear-gradient(90deg, #77ffad 0%, #5afffd 100%),
    linear-gradient(#ffffff, #ffffff);
  border-radius: 5px;
  opacity: 1;
}
.slide-2 {
  position: absolute;
  top: 218px;
  left: 78px;
  width: 625px;
  height: 354px;
  animation: bottomTotop 1s;
}
.slide-3 {
  position: relative;
  height: 850px;
  background: url(../assets/1-3.png) no-repeat;
  background-size: cover;
  background-position: center top;
  z-index: 2;
}
.slide2-2 {
  position: absolute;
  top: 290px;
  left: 360px;
  width: 696px;
  height: 230px;
}
.c2 {
  width: 100%;
  height: 500px;
  background: #353c56 url(../assets/c2-bg.jpg) no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
}

.c2 .c2-left {
  width: 646px;
  font-family: MicrosoftYaHei;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  margin-left: 76px;
  padding-top: 60px;
}
.c2 .c2-left h3 {
  margin-bottom: 40px;
}
.c2 .c2-left p {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #ffffff;
}
.c2 .con-w {
  display: flex;
  justify-content: space-between;
}
.c2 .c2-right img {
  position: relative;
  height: 500px;
  margin-top: -30px;
  z-index: 1;
  margin-right: 140px;
}

.c3 {
  width: 100%;
  height: 748px;
  background: #ececec url(../assets/c3-bg.jpg) no-repeat;
  background-attachment: fixed;
  background-size: 1920px 748px;
  background-position: center top;
}
.c3-text {
  width: 790px;
  font-family: MicrosoftYaHei;
  font-size: 18px;
  line-height: 40px;
  color: #485257;
  margin: 0 80px 0 auto;
}
.c3-text h3 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 66px;
  color: #d8584f;
  font-weight: bold;
  margin: 0 0 80px 113px;
  padding-top: 96px;
}
.c3-icon-lists {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 72px;
}
.c3-icon-lists li {
  position: relative;
  height: 130px;
}
.c3-icon-lists li img {
  position: relative;
  height: 130px;
  z-index: 1;
}
.c3-icon-lists li img.c3-i1 {
  position: absolute;
  top: 47px;
  left: 0px;
  width: 7;
  height: 19px;
  animation: toBottom1 1s infinite;
}

.c3-icon-lists li img.c3-i2 {
  position: absolute;
  top: 0;
  left: 12px;
  width: 13px;
  height: 50px;
  animation: toBottom2 1s infinite;
}
.c3-icon-lists li img.c3-i3 {
  position: absolute;
  top: 92px;
  left: 88px;
  width: 10px;
  height: 5px;
  z-index: 2;
  animation: toBottom3 1s infinite;
}
.c3-icon-lists li img.c3-i4 {
  position: absolute;
  top: 48px;
  left: 104px;
  width: 13px;
  height: 6px;
  animation: toBottom4 1s infinite;
}
.c3-icon-lists li img.c3-i5 {
  position: absolute;
  top: 39px;
  left: 124px;
  width: 10px;
  height: 34px;
  animation: toBottom5 1s infinite;
}
.c3 .c3-notes {
  text-align:right;
  color:#999;
  font-size:10px;
  margin-top:4px;
}

.c4 {
  position: relative;
  width: 100%;
  height: 753px;
  background: url(../assets/c4.png) no-repeat;
  background-attachment: fixed;
  /* background-size: 100% 753px; */
  background-size: cover;
  background-position: center top;
}
.scale-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 753px;
  transform: scale(4);
}
.c4 .c4-tit {
  font-family: MicrosoftYaHei-Bold;
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding: 70px 0 68px 0;
}
.c4 .c4-lists {
  display: flex;
  width: 1160px;
  margin: 0 auto;
  overflow: hidden;
}
.c4 .c4-lists li {
  position: relative;
  width: 220px;
  height: 475px;
  margin-right: 6px;
}
.c4 .c4-lists li.active {
  width: 708px;
}
.c4 .c4-lists li:last-child {
  margin-right: 0;
}
.c4 .list-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  height: 475px;
  background: #353c56 url(../assets/c4-item-bg.jpg) no-repeat;
  background-size: 220px 475px;
  text-align: center;
  color: #f7f7f7;
  font-family: MicrosoftYaHei;
  font-size: 14px;
}
.c4 .list-box h3 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 36px;
  font-weight: bold;
  padding-top: 146px;
}
.c4 .list-box p {
  line-height: 1.8;
}
.c4 .list-box .list-stit {
  font-size: 18px;
  padding: 10px 0 32px 0;
}

.c4 .list-inner-box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 708px;
  height: 475px;
  overflow: hidden;
}
.c4 .list-inner-box.active {
  display: block;
}
.c4 .list-inner-box img {
  /* width: 100%; */
  height: 100%;
}
.c4 .list-inner-box h3 {
  position: absolute;
  top: 297px;
  left: 36px;
  width: 645px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 36px;
  font-weight: bold;
  color: #f7f7f7;
}
.c4 .list-inner-box p {
  position: absolute;
  top: 357px;
  /* left: 72px; */
  left: 36px;
  width: 645px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  line-height: 25px;
  color: #f7f7f7;
}

.c5 {
  width: 100%;
  height: 730px;
  background: #ececec;
  background-attachment: fixed;
}
.c5 .c5-tit {
  padding: 72px 0 54px 0;
  text-align: center;
  font-family: MicrosoftYaHei-Bold;
  font-size: 48px;
  font-weight: bold;
  color: #d8584f;
}
.c5 .c5-tabs {
  display: flex;
  justify-content: space-between;
  /* width: 1466px; */
  width: 1160px;
  margin: 0 auto 12px auto;
}
.c5 .c5-tabs span {
  display: block;
  /* width: 240px; */
  width: 184px;
  /* height: 60px; */
  /* height: 65px; */
  background-color: #ffffff;
}
.c5 .c5-tabs span img {
  width: 184px;
}
.c5 .c5-list {
  position: relative;
  display: none;
  /* width: 1510px; */
  width: 1145px;
  height: 400px;
  background-color: #ffffff;
  /* padding-left: 90px; */
  padding-left: 55px;
}
.c5 .c5-list.active {
  display: block;
}
.c5 .c5-list .c5-list-tit {
  font-family: MicrosoftYaHei-Bold;
  font-size: 30px;
  font-weight: bold;
  color: #2484c7;
  padding-top: 40px;
}
.c5 .c5-list .c5-list-tit span {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  font-weight: normal;
  margin-left: 20px;
}
.c5 .c5-list .c5-list-line {
  position: relative;
  width: 540px;
  height: 2px;
  background-color: #2484c7;
  margin: 20px 0 36px 0;
}
.c5 .c5-list .c5-list-line i {
  position: absolute;
  top: -4px;
  left: 0;
  width: 200px;
  height: 10px;
  background-color: #2484c7;
}
.c5 .c5-list ul {
  display: flex;
  flex-wrap: wrap;
  width: 729px;
  font-family: SourceSansRoman-Regular;
  font-size: 18px;
  color: #485257;
}
.c5 .c5-list ul li {
  width: 360px;
  line-height: 2;
}
.c5 .c5-list-btn {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  background-color: #2484c7;
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin: 30px 0 20px 0;
  text-decoration: none;
}
.c5 .c5-class-logo {
  height: 50px;
}

.c5 .c5-list .class-list-logo {
  position: absolute;
  top: 0;
  right: 0;
}
.c5 .c5-list .class-list-logo-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 271px;
  height: 400px;
  background-color: #2484c7;
}
.c5 .c5-list .class-list-logo img {
  position: absolute;
  top: 122px;
  right: 0;
  height: 278px;
}
.c5 .c5-list .c5-list-foot-line {
  position: absolute;
  right: 0;
  width: 274px;
  bottom: -78px;
  height: 13px;
  background-color: #2484c7;
}
.width500 {
  width: 500px !important;
}
.width420 {
  width: 420px !important;
}
.width300 {
  width: 300px !important;
}
.cred {
  color: #d8584f !important;
}
.bred {
  background: #d8584f !important;
}
.cpur {
  color: #be4086 !important;
}
.bpur {
  background: #be4086 !important;
}
.corange {
  color: #f19149 !important;
}
.borange {
  background: #f19149 !important;
}
.cblue {
  color: #50c1e3 !important;
}
.bblue {
  background: #50c1e3 !important;
}
.cblue2 {
  color: #006cb8 !important;
}
.bblue2 {
  background: #006cb8 !important;
}
.c5 .class-logo2 {
  height: 68px;
}
.c6 {
  position: relative;
  width: 100%;
  height: 600px;
  background: url(../assets/c6-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
}

.c6 .c6-img1 {
  position: absolute;
  /* left: 30.2%; */
  top: 200px;
  left: 140px;
  width: 240px;
  height: 430px;
}
.c6 .c6-txt {
  position: absolute;
  /* left: 48.95%; */
  /* left: 576px; */
  right: 80px;
  top: 272px;
  /* top: 336px; */
  /* width: 820px; */
  width: 556px;
  font-family: MicrosoftYaHei;
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
  text-shadow: 0px 1px 5px 0px #20303c;
}
.c6 .c6-txt span {
  display: block;
  text-align: right;
  width: 100%;
}
.c7 {
  width: 100%;
  height: 600px;
  background: url("../assets/c7.png") no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
}
.c7 .tit {
  font-family: MicrosoftYaHei-Bold;
  font-size: 48px;
  font-weight: bold;
  color: #ececec;
  text-align: center;
  padding: 76px 0 58px 0;
}
.c7 .c7-flex {
  display: inline-flex;
  padding: 0 50px;
}
.c7 .c7-left {
  width: 396px;
  height: 297px;
  background: url(../assets/news-img.png) no-repeat;
  /* background-size: 297px 224px; */
  background-size: contain;
}
.c7 .c7-middle {
  position: relative;
  width: 348px;
  border-right: 1px solid #ececec;
}
.c7-middle h3 {
  width: 264px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 24px;
  font-weight: bold;
  color: #ececec;
  margin: 0 auto;
  padding-top: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.c7-middle p {
  width: 264px;
  line-height: 36px;
  font-size: 18px;
  color: #ececec;
  margin: 26px auto 0 auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.c7-middle .c7-btn {
  position: absolute;
  left: 33px;
  bottom: 10px;
  width: 160px;
  height: 40px;
  background: url(../assets/c7-btn.png) no-repeat;
  background-size: 160px 40px;
}
.c7 .c7-right h3 {
  font-size: 22px;
  margin-left: 42px;
  color: #ececec;
  padding-top: 20px;
}
.c7 .c7-right ul {
  margin-left: 42px;
}
.c7 .c7-right ul li {
  width: 300px;
  border-bottom: dotted 1px #ececec;
  padding-bottom: 18px;
  padding-top: 19px;
  font-size: 18px;
  color: #ececec;
}
.c7 .c7-right ul li:last-child {
  border: none;
}

.c7 .c7-right ul li a {
  display: block;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  font-size: 18px;
  color: #ececec;
}

@media (min-width: 1600px) {
  .con-w {
    width: 1600px;
    margin: 0 auto;
  }
  .c2 .c2-left {
    width: 800px;
    margin-left: 196px;
  }
  .c2 .c2-right img {
    /* left: 55.25%; */
    margin-right: 260px;
  }
  .c3-text {
    margin: 0 90px 0 600px;
  }
  .c4 .c4-lists {
    width: 1600px;
  }
  .c4 .list-box {
    width: 260px;
    background: url(../assets/c4-item-bg.jpg) no-repeat;
    background-size: 260px 475px;
  }
  .c4 .c4-lists li {
    width: 260px;
  }
  .c4 .list-inner-box {
    width: 1068px;
  }
  .c4 .c4-lists li.active {
    width: 1068px;
  }
  .c5 .c5-tabs {
    width: 1466px;
  }
  .c5 .c5-tabs span {
    width: 240px;
  }
  .c5 .c5-tabs span img {
    width: 240px;
  }
  .c5 .c5-list {
    width: 1510px;
    padding-left: 90px;
  }
  .c5 .c5-class-logo {
    height: 62px;
  }
  .c5 .c5-list .class-list-logo-bg {
    width: 470px;
  }
  .c5 .c5-list .class-list-logo img {
    /* width: 495px; */
    height: 347px;
    right: 92px;
    top: 54px;
  }
  .c5 .c5-list .c5-list-foot-line {
    bottom: -68px;
    width: 471px;
  }
  .c6 .c6-img1 {
    left: 260px;
  }
  .c6 .c6-txt {
    width: 810px;
    top: 324px;
    /* left: 772px; */
    right: 198px;
  }
  .c7 .c7-flex {
    padding: 0;
  }
  .c7 .c7-left {
    width: 440px;
    height: 332px;
    margin-left: 40px;
  }
  .c7 .c7-middle {
    width: 726px;
  }
  .c7-middle h3 {
    width: 620px;
    padding-top: 64px;
  }
  .c7-middle p {
    width: 620px;
  }
  .c7-middle .c7-btn {
    bottom: 0;
    left: 44px;
  }
  .c7 .c7-right ul li {
    width: 310px;
  }
  /* .c7 .c7-right ul li a {
    width: 394px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */

  .slide-2 {
    left: 270px;
  }
  .slide2-2 {
    left: 560px;
  }
}
@keyframes bottomTotop {
  0% {
    top: 248px;
  }
  100% {
    top: 218px;
  }
}
@keyframes bottomTotop2 {
  0% {
    top: 310px;
  }
  100% {
    top: 290px;
  }
}
@keyframes toBottom1 {
  0% {
    top: 47px;
  }
  50% {
    top: 52px;
  }
  100% {
    top: 47px;
  }
}
@keyframes toBottom2 {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}
@keyframes toBottom3 {
  0% {
    top: 87px;
  }
  50% {
    top: 92px;
  }
  100% {
    top: 87px;
  }
}
@keyframes toBottom4 {
  0% {
    top: 53px;
  }
  50% {
    top: 48px;
  }
  100% {
    top: 53px;
  }
}
@keyframes toBottom5 {
  0% {
    top: 34px;
  }
  50% {
    top: 39px;
  }
  100% {
    top: 34px;
  }
}
</style>
