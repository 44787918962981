<template>
  <div id="app">
    <!-- <router-view /> -->
    <my-Navigation></my-Navigation>
    <index-content></index-content>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myNavigation from "@/components/myNavigation";
import IndexContent from "@/components/IndexContent";
import myFooter from "@/components/myFooter";
export default {
  name: "App",
  components: {
    myNavigation,
    IndexContent,
    myFooter,
  },
};
</script>

<style></style>
