<template>
  <div class="footer">
    <div class="con-w">
      <div class="footer-left">
        <ul>
          <li v-for="(list, ind) in footerLists" :key="ind">
            <h3>{{ list.type }}</h3>
            <a
              :href="item.url"
              target="_blank"
              v-for="(item, index) in list.lists"
              :key="index"
              >{{ item.txt }}</a
            >
          </li>
        </ul>
      </div>
      <div class="footer-right">
        <h3>相关品牌</h3>
        <img src="../assets/footer-logo.png" alt="" />
      </div>
    </div>
    <div class="chh_index_footer">
      <p class="chh_base">
        Copyright 2011-2021 北京千锋互联科技有限公司
        <a href="https://beian.miit.gov.cn/">京ICP备12003911号-2</a>
        <a
          href="http://tongji.baidu.com/web/welcome/login"
          target="_blank"
          title="百度统计"
          >百度统计</a
        >
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035718"
          ><img
            style="vertical-align: middle"
            src="http://img.mobiletrain.org/templates/mobiletrain/hcxy/images/index/beian.png"
          />

          京公网安备 11010802035718号</a
        >
      </p>
    </div>
  </div>
</template>
<!-- <script src="https://s19.cnzz.com/z_stat.php?id=1262697339&web_id=1262697339"></script> -->
<script>
export default {
  name: "myFooter",
  data() {
    return {
      footerLists: [
        {
          type: "教育培训",
          lists: [
            {
              txt: "千锋教育",
              url: "http://www.qfedu.com/",
            },
            {
              txt: "好程序员",
              url: "http://www.goodprogrammer.org/",
            },
            {
              txt: "小狮视觉",
              url: "http://www.xiaoshiedu.cn/",
            },
            {
              txt: "扣丁学堂",
              url: "http://www.codingke.com/",
            },
            {
              txt: "锋企优联",
              url: "http://www.fengqiyoulian.com/",
            }
          ],
        },
        {
          type: "高校服务",
          lists: [
            {
              txt: "校企合作",
              url: "http://www.fengyunedu.cn/collegeCooperation",
            },
            {
              txt: "高校教辅平台",
              url: "http://tea.fengyunedu.cn/",
            },
            {
              txt: "锋云录",
              url: "https://fyl.fengyunedu.cn/",
            },
          ],
        },
        {
          type: "企业服务",
          lists: [
            {
              txt: "企业内训",
              url: "http://www.mobiletrain.org/qynx/",
            },
            {
              txt: "人才服务",
              url: "http://www.1000phone.com/service.html",
            },
          ],
        },
        {
          type: "千锋教研院",
          lists: [
            {
              txt: "教研实力",
              url: "http://www.mobiletrain.org/project2021/index.shtml",
            },
            {
              txt: "教材书籍",
              url: "http://books.1000phone.com/",
            },
            {
              txt: "视频教程",
              url: "http://video.mobiletrain.org/",
            },
          ],
        },
      ],
    };
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
a {
  cursor: pointer;
  text-decoration: none;
}
.con-w {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.footer {
  background-color: #353c56;
}
.footer .con-w {
  display: flex;
}
.footer-left ul {
  display: flex;
  padding-left: 55px;
  padding: 102px 0 102px 55px;
}
.footer-left ul li {
  width: 158px;
}
.footer-left ul li:last-child {
  width: 130px;
}
.footer-left ul li h3,
.footer-right h3 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 22px;
  font-weight: bold;
  color: #ececec;
  margin-bottom: 38px;
}
.footer-left ul li a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  color: #ececec;
  opacity: 0.7;
  line-height: 30px;
}
.footer-left ul li a:hover {
  color: #0b9af8;
}
.footer-right {
  height: 174px;
  border-left: 1px solid rgba(95, 98, 107, 0.3);
  margin-top: 102px;
  padding-left: 28px;
}
.footer-right img {
  height: 66px;
}
.chh_index_footer {
  background: #101010;
  height: 46px;
  line-height: 46px;
}
.chh_index_footer p {
  text-align: center;
  /* width: 1002px; */
}
.chh_index_footer p,
.chh_index_footer a {
  color: #999;
  font-size: 12px;
}
.chh_index_footer a {
  margin-right: 3px;
}
.chh_index_footer a:hover {
  text-decoration: underline;
}
@media (min-width: 1600px) {
  .con-w {
    width: 1600px;
    margin: 0 auto;
  }
  .footer-left ul li {
    width: 200px;
  }
  .footer-left ul li:last-child {
    width: 228px;
  }
  .footer-right {
    padding-left: 107px;
  }
}
</style>
